:root {
  --primary-colour: #f6e21c;
  --secondary-colour: #e90000;
  --secondary-colour-hover: #c00000;
}

a {
  color: var(--secondary-colour);
  text-decoration: none;
}

a:hover, a:active {
  color: var(--secondary-colour-hover);
  text-decoration: underline;
}

button, a {
  transition: all 0.2s;
}

header .innerHeader {
  background-color: var(--primary-colour);
  padding: 50px;
}


.logoWrapper {
  transform: rotate(-5deg);
  background-color: var(--secondary-colour);
  border: 10px solid var(--primary-colour);
  -webkit-box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.75);
  max-width: 350px;
  margin: auto;
}

header h1 {
  font-family: serif;
  color: #fff;
  margin: 0;
  font-size: 3rem;
}
header h1 .line1 {
  padding: 10px;
  text-transform: uppercase;
  display: block;
  border-top: 6px solid #000;
  border-left: 6px solid #000;
  border-right: 6px solid #000;
  border-bottom: 2px solid #000;
}
header h1 .line2 {
  font-size: 2.8rem;
  text-transform: lowercase;
  font-style: italic;
  display: block;
  background-color: #000;
  border-top: 3px solid var(--primary-colour);
  border-bottom: 3px solid var(--primary-colour);
  height:35px;
  line-height: 0.45
}
header h1 .line2 .star {
  font-size: 1.5rem;
  font-style: normal;
  margin: 0 5px;
}
header h1 .line3 {
  font-size: 3.5rem;
  text-transform: uppercase;
  display: block;
  border-top: 2px solid #000;
  border-left: 6px solid #000;
  border-right: 6px solid #000;
  border-bottom: 6px solid #000;
}

p.site-description {
  margin: 50px 0;
  font-size: 1.4rem;
}

.generatedParagraphs {
  margin: 20px;
}


.generatedParagraphs > div {
  border: 1px solid #000;
  background-color: #fff;
  max-width: 760px;
  margin: auto;
  padding: 10px 20px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s linear;
}

.generatedParagraphs > div.visible {
  visibility: visible;
  opacity: 1;
}

.drop-shadow {
  position: relative;
}
.drop-shadow:before, .drop-shadow:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width:300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.drop-shadow:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

button {
  font-size: 1rem;
  background-color: var(--secondary-colour);
  border-radius: 10px;
  padding: 10px 15px;
  margin: 20px 10px 10px 0;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
}
button:hover {
  background-color: var(--secondary-colour-hover);
}

input {
  font-size: 1rem;
  padding: 10px;
  width: 80px;
}

input+button {
  border-radius: 0 10px 10px 0;
}

footer {
  padding: 0 20px;
}

@media only screen and (max-width: 600px) {
  .logoWrapper { max-width: 220px; }
  header h1 .line1 { font-size: 1.6rem; }
  header h1 .line2 { font-size: 1.8rem; line-height: 0.5; height: 25px; }
  header h1 .line3 { font-size: 2rem; }
  header h1 .line2 .star { font-size: 1rem; margin: 0 3px; }
  p.site-description { font-size: 1.2rem; }
}